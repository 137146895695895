import { Box, Stack, Typography } from "@mui/material";

import NeedHelpContactSupport from "./NeedHelpContactSupport";

const EmptyState = () => {
  return (
    <Stack>
      <Box display={"flex"} justifyContent={"center"} mt={25}>
        <Typography variant="h6">No data to display.</Typography>
      </Box>
      <Box textAlign="center" mt={2}>
        <NeedHelpContactSupport />
      </Box>
    </Stack>
  );
};

export default EmptyState;
