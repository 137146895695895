import { useEffect, useRef, useState } from "react";

import { Tooltip, Typography } from "@mui/material";
import { TypographyPropsVariantOverrides } from "@mui/material/Typography/Typography";
import { Variant } from "@mui/material/styles/createTypography";
import { OverridableStringUnion } from "@mui/types";

const TextWithTooltip = ({
  text,
  variant = "body1",
  sx,
}: {
  text: string | undefined;
  variant?: OverridableStringUnion<
    Variant | "inherit",
    TypographyPropsVariantOverrides
  >;
  sx?: React.CSSProperties;
}) => {
  const [isTruncated, setIsTruncated] = useState(false);

  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkTruncation = () => {
      const containerWidth = textRef.current?.offsetWidth ?? 0;
      const textWidth = textRef.current?.scrollWidth ?? 0;
      setIsTruncated(textWidth > containerWidth);
    };

    checkTruncation();
    window.addEventListener("resize", checkTruncation);

    return () => {
      window.removeEventListener("resize", checkTruncation);
    };
  }, [text]);

  return (
    <Tooltip title={isTruncated ? text : ""} arrow placement="top">
      <Typography
        ref={textRef}
        variant={variant}
        sx={{
          color: "primary",
          alignSelf: "center",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          ...sx,
        }}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};

export default TextWithTooltip;
