import { alpha, Skeleton, Stack, useTheme } from "@mui/material";

const LoadingSkeleton = () => {
  const theme = useTheme();
  return (
    <>
      <Skeleton
        variant="rectangular"
        height={40}
        width={200}
        sx={{
          mt: 3,
          backgroundColor: alpha(theme.palette.secondary.main, 0.1),
          borderRadius: 1,
        }}
      />
      <Stack spacing={2} mt={3}>
        {[...Array(5)].map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            height={60}
            sx={{
              backgroundColor: alpha(theme.palette.secondary.main, 0.1),
              borderRadius: 1,
            }}
          />
        ))}
      </Stack>
    </>
  );
};

export default LoadingSkeleton;
