import { TableCell, TableSortLabel } from "@mui/material";

import { Order_By } from "../../generated/graphql";

import { CollapsibleTableHeader } from "./types";

const SortableHeaderCell = ({
  header,
  sortField,
  sortDirection,
  handleUpdateSort,
}: {
  header: CollapsibleTableHeader;
  sortField: string;
  sortDirection: Order_By | undefined;
  handleUpdateSort: (
    header: CollapsibleTableHeader,
    sortDirection?: Order_By
  ) => void;
}) => {
  return (
    <>
      {header.sortable ? (
        <TableCell key={header.name}>
          <TableSortLabel
            active={sortField === header.name}
            onClick={() => {
              const newDirection =
                sortDirection === Order_By.Asc ? Order_By.Desc : Order_By.Asc;
              handleUpdateSort(header, newDirection);
            }}
            direction={sortDirection === Order_By.Asc ? "asc" : "desc"}
          >
            {header.label}
          </TableSortLabel>
        </TableCell>
      ) : (
        <TableCell key={header.name}>{header.label}</TableCell>
      )}
    </>
  );
};
export default SortableHeaderCell;
