import { useState } from "react";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";

import { Order_By } from "../../generated/graphql";

import { CollapsibleTableHeader } from "./types";

const SortButton = ({ direction }: { direction: Order_By | undefined }) => {
  return direction === Order_By.Asc ? (
    <ArrowUpwardIcon />
  ) : (
    <ArrowDownwardIcon />
  );
};

const MobileSort = ({
  columns,
  sortField,
  sortDirection,
  handleUpdateSort,
}: {
  columns: CollapsibleTableHeader[] | undefined;
  sortField: string;
  sortDirection: Order_By | undefined;
  handleUpdateSort: (
    header: CollapsibleTableHeader,
    sortDirection?: Order_By
  ) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleSortButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (!columns) return null;

  return (
    <Box>
      <Button
        variant="text"
        onClick={handleSortButtonClick}
        endIcon={<ImportExportIcon />}
        sx={{ whiteSpace: "nowrap" }}
      >
        Sort By
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {columns?.map((header, idx) => (
          <MenuItem
            key={header.name + idx}
            onClick={() => {
              const newDirection =
                sortDirection === Order_By.Asc ? Order_By.Desc : Order_By.Asc;
              handleUpdateSort(header, newDirection);
            }}
          >
            <ListItemIcon>
              <>
                {sortField === header.name && (
                  <SortButton direction={sortDirection} />
                )}
              </>
            </ListItemIcon>
            <ListItemText>{header.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default MobileSort;
